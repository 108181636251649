import React from 'react';
import Layout from '@layouts';
import { Banners, SEO, DonationCancelled } from '@components';

const { Banner } = Banners;

const Index = () => (
  <Layout bannerChildren={<Banner title='Donation Cancelled' />}>
    <SEO title='DONATION CANCELLED' description='Donation Cancelled' />
    <article id='index-article'>
      <DonationCancelled />
    </article>
  </Layout>
);

export default Index;
